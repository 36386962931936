import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Container from "../atoms/Container"

const Holder = styled.div`
  overflow: hidden;
  position: relative;
  min-height: ${(props) => (props.home ? "100vh" : "calc(50vh + 2rem)")};
  margin: ${(props) =>
    props.notFound || props.noPadding ? "-2rem 0 0 0" : "-2rem 0 1rem 0"};
  > :first-child,
  > :nth-child(2) {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  padding: 6rem 0 3rem 0;
  @media (${(props) => props.theme.breakpoints.sm}) {
    margin: ${(props) => (props.home ? "-2rem 0 1rem 0" : "-2rem 0 -8rem 0")};
    padding: ${(props) => (props.noBottom ? "6rem 0 0 0" : "6rem 0 3rem 0")};
    padding: ${(props) =>
      props.noPadding ? "6rem 0 14rem 0" : "8rem 0 14rem 0"};
  }
`

const Bg = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => (props.bg ? props.bg : props.theme.colours.grey)};
  transform-origin: top left;
`
const Content = styled.div`
  padding-top: ${(props) => (props.noPadding ? "0" : "3rem")};
  max-width: ${(props) => (props.work ? "35rem" : "none")};
`

const TextRow = styled.div`
  h1 {
    margin: 0;
    ${(props) => props.theme.fluidType(8)};
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(10)};
    }
  }
  margin-bottom: ${(props) => (props.work ? "4rem" : "0")};
`

const InnerContainer = styled.div`
  min-height: ${(props) => (props.home ? "85vh" : "unset")};
  @media (${(props) => props.theme.breakpoints.sm}) {
    min-height: ${(props) => (props.home ? "75vh" : "unset")};
  }
  @media (max-height: 480px) {
    min-height: ${(props) => (props.home ? "60vh" : "unset")};
  }
`

function Splash({
  children,
  text,
  bg,
  work,
  splashClass,
  home,
  notFound,
  noPadding,
  noBottom,
}) {
  return (
    <Holder
      className={splashClass}
      home={home}
      notFound={notFound}
      noPadding={noPadding}
      noBottom={noBottom}
    >
      <Bg bg={bg} className="splash-bg" />
      <Container>
        <InnerContainer home={home}>
          <Content className="splash-content" work={work} noPadding={noPadding}>
            <TextRow work={work}>
              <h1 className="serif">{text}</h1>
            </TextRow>
            {children}
          </Content>
        </InnerContainer>
      </Container>
    </Holder>
  )
}

Splash.propTypes = {
  splashClass: PropTypes.string,
  bg: PropTypes.string,
  text: PropTypes.element.isRequired,
  work: PropTypes.bool,
  home: PropTypes.bool,
  notFound: PropTypes.bool,
}

Splash.defaultProps = {
  work: false,
  home: false,
  notFound: false,
}

export default Splash
