import React, { useRef, useEffect } from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ArrowText from "../components/atoms/ArrowText"
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import videoSrc from "../../static/videos/YouCanCallMyAl.mp4"
import thumb from "../../static/images/cw_home.png"

const VidHolder = styled.div`
  video {
    position: absolute;
    filter: opacity(0.8);
    top: 0;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center 30%;
    @media (${(props) => props.theme.breakpoints.xl}) {
      object-position: center 15%;
    }
  }
`

function NotFoundPage() {
  const vidRef = useRef(null)

  function playVid() {
    const playPromise = vidRef.current.play()

    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          vidRef.current.muted = false
          document.body.removeEventListener("click", playVid)
          // Automatic playback started!
        })
        .catch(function (error) {
          vidRef.current.muted = true
          vidRef.current.play()
        })
    }
  }

  useEffect(() => {
    if (vidRef && vidRef.current) {
      document.body.addEventListener("click", playVid)
      playVid()
    }
  })

  return (
    <>
      <Seo title="404: Not found | Craig Walker" thumb={thumb} />
      <VidHolder className="splash-bg splash-content">
        <video
          ref={vidRef}
          loop
          playsInline
          controls
          src={videoSrc}
          type={"video/mp4"}
        >
          <track kind="captions" />
        </video>
      </VidHolder>
      <Splash
        notFound={true}
        text={
          <>
            <b>Oops! Page not found.</b>
            <br />
            It may have moved, or&nbsp;been deleted.
          </>
        }
        bg="#fff"
      >
        <ArrowText>
          <p>
            <Link to="/">Maybe go back home and try&nbsp;again?</Link>
          </p>
        </ArrowText>
      </Splash>
    </>
  )
}

export default withTheme(NotFoundPage)
