import React from "react"
import styled from "styled-components"
import { PropTypes } from "prop-types"

const Holder = styled.div`
  position: relative;
  max-width: ${(props) =>
    props.large ? 1200 : props.theme.typography.maxScreen}px;
  padding: 0 1rem;
  margin: ${(props) => (props.margins ? "1rem auto" : "0 auto")};
  @media (${(props) => props.theme.breakpoints.sm}) {
    padding: 0 1.5rem;
  }
  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 0 3rem;
  }
  @media (${(props) => props.theme.breakpoints.xl}) {
    padding: 0;
  }
`

function Container({ margins, children, large }) {
  return (
    <Holder margins={margins} large={large} className="ww-container">
      {children}
    </Holder>
  )
}

Container.propTypes = {
  margins: PropTypes.bool,
  large: PropTypes.bool,
}

Container.defaultProps = {
  margins: true,
  large: false,
}

export default Container
