import React from "react"
import styled, { keyframes, css } from "styled-components"
import IconArrow from "./iconArrow"
import PropTypes from "prop-types"

const bounce = keyframes`
  0% { transform: translateY(0) rotate(90deg); }
  25% { transform: translateY(-0.75rem) rotate(90deg); }
  50% { transform: translateY(0) rotate(90deg); }
  100% { transform: translateY(0) rotate(90deg); }
`
const animationSetting = css`
  ${bounce} 2s ease-in-out infinite
`

const Holder = styled.div`
  position: ${(props) => (props.home ? "absolute" : "unset")};
  bottom: ${(props) => (props.home ? "0rem" : "unset")};
  left: ${(props) => (props.home ? "20px" : "unset")};
  color: ${(props) => (props.home ? "white" : "unset")};
  margin-top: ${(props) => (props.smallMarginTop ? "3rem" : "8rem")};
  max-width: 16rem;
  svg {
    transform: rotate(90deg);
    margin-left: 0.2rem;
    animation: ${(props) => (!props.noAnimation ? animationSetting : "")};
    path {
      stroke: ${(props) => (props.home ? "white" : props.theme.colours.black)};
    }
  }
`

function ArrowText({ children, home, smallMarginTop, noAnimation }) {
  return (
    <Holder
      home={home}
      smallMarginTop={smallMarginTop}
      noAnimation={noAnimation}
    >
      <IconArrow />
      {children}
    </Holder>
  )
}

ArrowText.propTypes = {
  home: PropTypes.bool,
}

ArrowText.defaultProps = {
  home: false,
}

export default ArrowText
